.control_panel {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-areas: "filter buttons";
  padding-left: 1vw;
  margin: 1vw;
  align-items: center;
  height: 5vh;
}

.filter {
  grid-area: filter;
}

.filter input {
  width: 30%;
}

.buttons {
  grid-area: buttons;
  display: flex;  
  border: 5px rgb(102, 209, 229) solid;
}

.buttons button {
  width: 100%;
  font-size: 1.2vw;
  color: white;
  background-color: rgb(102, 209, 229);
  border: none;
  height: 5vh;
}

.buttons button:hover {
  width: 100%;
  font-size: 1.2vw;
  color: rgb(102, 209, 229);
  background-color: white;
  border: none;
  height: 5vh;
}

.table_container {
  margin: 0 1vw 1vw 1vw;
}

.tab_content {
  width: 100%;
  border-collapse: collapse;
  border-style: groove;
  border: 5px rgb(102, 209, 229) solid;
  overflow: auto;
}

.tab_content th {
  height: 20px;
  background-color: rgb(102, 209, 229);
  color: white;
  height: 10vh;
}

.table-data {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.patient_content tr:nth-child(even) {
  background-color: white;
}

.del-button {
  padding: 8px 16px;
  border: none;
  background-color: #f44336;
  font-size: 16px;
  border-radius: 20px;
  color: white;
}

.del-button:hover {
  background-color: red;
}

.upd-button {
  padding: 8px 16px;
  border: none;
  background-color: #4caf50;
  font-size: 16px;
  border-radius: 20px;
  color: white;
}

.upd-button:hover {
  background-color: green;
}
