input {
  height: 2.5vh;
}

.PENDIENTE {
  background-color: red;
}

.EN{
  background-color: gray;
}

.NOVEDAD{
  background-color: green;
}
