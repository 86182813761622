.main_container {
  background-image: url("../assets/img/background_1.png");
  display: grid;
  grid-template-rows: 8vh 92vh;
  grid-template-columns: 3vw 94vw 3vw;
  grid-template-areas: "selector selector selector" 
  ". container .";
}

.render_selector{
  grid-area: selector;
  text-align: center;
  display: flex;
}

.content_container {
  background-color: white;
  grid-area: container;
  overflow-y: scroll;
  margin-bottom: 2vh;
  padding: 1vw;
}

.title_header {
  display: grid;
  grid-area: title_header;
  text-align: center;
  background-color: rgb(102, 209, 229);
  color: white;
  align-items: center;
}
