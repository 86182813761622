.SIN {
  background-color: red;
}

.OK {
  background-color: green;
}

.Disclaimer{
  border: black 1px solid;
}
