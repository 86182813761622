.welcome_msg {
  text-align: center;
  font-size: 3vw;
  color: rgb(102, 209, 229);
}

.menu {
  border: 5px rgb(102, 209, 229) solid;
  padding: 0 1vw 1vw 1vw;
  margin: 1vw;
}

.menu label {
  display: block;
  margin: 1vw;
}

.menu h2 {
  font-size: 2vw;
  color: rgb(102, 209, 229);
}

.test {
  height: 25px;
}
