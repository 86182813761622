.PDFViewer {
  width: 100%;
  height: 50%;
}

td {
  border: 1px solid rgb(102, 209, 229);
  text-align: center;
  padding: 5px;
}
