.autorizado {
  background-color: green;
}
.no-autorizado {
  background-color: red;
}
.cerrado{
    background-color: grey;
}
.default-color{
    background-color: null;
}