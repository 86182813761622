.suggestions {
  color: black;
}

.Autoinput { display: inline;
  padding: 3px;
  color: black;
  width: 40%;
  height: 2.5vh;
}

.Inputs {
  padding: 3px;
}

.react-autosuggest__suggestions-container--open {
  position: absolute;
  border: 2px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 10;
  max-height: 40vh;
  overflow: auto;
  margin-top: 2px;
  margin-left: 22vw;
  left: 0;
  right: 0;
  width: 30%;
}

.react-autosuggest__suggestions-list {
  list-style: none;
  margin-bottom: 0px;
  padding-left: 0px;
  cursor: pointer;
}

.react-autosuggest__input {
  width: 200px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}
