.full-container {
  background-image: url("../assets/img/background_1.png");
  display: grid;
  grid-template-columns: 30vw 40vw 30vw;
  grid-template-rows: 10vh 80vh 10vh;
  grid-template-areas:
    ". . ."
    ". login-container ."
    ". . .";
  color: white;
}

.login-container {
  grid-area: login-container;
  display: grid;
  grid-template-columns: 6% 88% 6%;
  grid-template-rows: 6% 88% 6%;
  grid-template-areas:
    ". . ."
    ". login-menu."
    ". . .";
  border: solid 5px white;
  border-radius: 20px;
}

.login {
  background-color: white;
  padding: 20px;
  grid-area: login-menu;
  text-align: center;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  border: 2px solid rgb(102, 209, 229);
  color: rgb(102, 209, 229);
}

.user-label {
  display: inline-block;
  width: 90%;
  margin: 6px;
  font-size: 1.35vw;
  text-align: left;
}

.user-input {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgb(102, 209, 229);
  border-radius: 3px;
  width: 90%;
  height: 6vh;
  padding: 5px;
  margin: 6px;
}

.login-button {
  position: relative;
  font-size: 20px;
  top: 10px;
  color: white;
  background-color: rgb(102, 209, 229);
  border: none;
  border-radius: 5px;
  height: 10vh;
  width: 80%;
}

.title-label {
  font-size: 2.4vw;
  font-weight: bold;
}

.popup-label {
  font-size: 1.6vw;
  color: rgb(102, 209, 229);
  font-weight: bold;
}
